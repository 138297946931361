import { EventEmitter, Injectable } from '@angular/core';
import { Tenant } from '../../store/reviewer/reviewer.interface';
import { StorageService } from './storage.service';
import { MessagingCountHash } from '../../modules/client/dashboard/message/message.interface';

@Injectable({
  providedIn: 'root'
})
export class EmittersService {
  tenantEmitter:EventEmitter<Tenant> = new EventEmitter();
  searchMarketSelectorEmitter: EventEmitter<any> = new EventEmitter();
  skillAndExperienceEmitter: EventEmitter<any> = new EventEmitter();
  credentialEmitter: EventEmitter<any> = new EventEmitter();
  myProjectEmitter: EventEmitter<boolean> = new EventEmitter();
  reviewerListEmitter: EventEmitter<void> = new EventEmitter();
  staffTabEmitter: EventEmitter<void> = new EventEmitter();
  messageCountEmitter: EventEmitter<MessagingCountHash> = new EventEmitter();
  todoListEmitter: EventEmitter<void> = new EventEmitter();

  constructor(private storageService:StorageService) {
    this.tenantEmitter.subscribe((event)=>{
        this.storageService.setTenant(event);
    });
    this.searchMarketSelectorEmitter.subscribe((event)=>{
      this.storageService.setSelectedSearchMarkets(event);
  });
   }
}
